import { useRef, useState, useEffect } from "react";
import Image from 'next/image'
import Head from 'next/head'

import Layout from '../components/layout-alpha'
import ContactMap from '../components/contact-map2'

import EcranSvg from '../components/ecran-svg';
import PhoneSvg from '../components/phone-svg';
import OrdiSvg from '../components/ordi-svg';

import Marques from '../components/marques';



export default function Homepage({ preview, children, siteEnv, marques }) {

  const [menu, setMenu] = useState(false);

  const changePosition = () => {
    // if(window.scrollY >= 1000 ){
    //   setMenu(true);
    // }
    // else{
    //   setMenu(false);
    // }
  }
  // useEffect(() => {
  //   window.addEventListener('scroll', changePosition);
  // }, []);

  //var loading = true;


  const inputRef = useRef();
  const scrollHandler = _ => {
    if(inputRef.current && inputRef.current.getBoundingClientRect().top <=0 && inputRef.current.getBoundingClientRect().top >= -(inputRef.current.getBoundingClientRect().height - 700) ){
      setMenu(true);
    }
    else{
      setMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);}, []);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    var onMove = false;
    if(typeof(window) !== "undefined"){
      $ = window.jQuery;
      $(".nav-range").removeClass("actif");
      $(".nav-1").addClass("actif");

      if(loading){
        /* ***************************************************
        *	Zone 1
        */
        if(typeof Snap !== 'undefined'){
          
          var s = Snap("#svg");
          Snap.plugin( function( Snap, Element, Paper, global ) {
            Element.prototype.addTransform = function( t ) {
              return this.transform( this.transform().localMatrix.toTransformString() + t );
            };
          });
  
          //fond
          var fond = s.rect(0, 0, $(window).width(), $(window).height());
          fond.attr({fill:"#3c3a38", opacity: "1"});
          //logo
          var p21 = s.path("M108.9,2.8 97.1,2.8 97.1,2.8 108.9,2.8").attr({fill: "#ff7300",});
          var p22 = s.path("M111.5,36.3 117.5,21.5 117.5,21.5 111.5,36.3 M85.9,98 80.8,109 80.8,109 85.9,98 ").attr({fill: "#ff7300",}); 
          var p22Bis = s.path("M157,109 117.5,21.5 111.5,36.3 138,98 85.9,98 80.8,109").attr({opacity: 0,}); //pour caller l'animation 
          var p23 = s.path("M41.4,89.8 47.4,76.4 47.4,76.4 41.4,89.8 M73,16 78.2,2 78.2,2 73,16" ).attr({opacity: "5.000000e-002",});
          var p23Bis = s.path("M2,2 41.4,89.8 47.4,76.4 21,16 73,16 78.2,2").attr({opacity: 0,}); //pour caller l'animation 
          var logo2 = s.group(p21,p22,p22Bis,p23, p23Bis);
          logo2.transform('t'+($(window).width()/2-100)+','+($(window).height()/2-100)+'').addTransform('s4');
    
          //animation du premier trait
          p21.animate({ d: "M108.9,2.8 97.1,2.8 49.1,109.8 62.6,109.8" }, 500,mina.easeOut); 
          //animation des 2 chevrons
          setTimeout(function () {
            p22.animate({ d: "M111.5,36.3 117.5,21.5 157,109 138,98 M85.9,98 80.8,109 157,109 138,98 " }, 1000,mina.easeOut); 
            p23.animate({ d: "M41.4,89.8 47.4,76.4 21,16 2,2 M73,16 78.2,2  2,2   21,16" }, 1000,mina.easeOut); 
            $(".videoContainer").css("display","block");
          }, 500);
          setTimeout(function () {
            logo2.animate({opacity: "0.1"}, 2000,mina.easeOut);
            fond.animate({ opacity: "0.9",}, 2000,mina.easeOut);
            
            $("#digital-title").animate({opacity: "1"}, 3000,mina.easeOut);
            $("#digital-scroll").animate({opacity: "1"}, 3000,mina.easeOut);
            $("#nav-bar").animate({opacity: "1"}, 3000,mina.easeOut);;
            $(".header").animate({opacity: "1"}, 3000,mina.easeOut);
            $("#headerSection").animate({opacity: "1"}, 3000,mina.easeOut);
            
          }, 1500);
          setLoading(false);
        }
      }	
      $(window).on('scroll touchmove',function()
      {
        if($('#zone1').offset() && $('#zone4').offset()){
        let midHeight = $(window).height()/2;
        
        if($(window).scrollTop() < $('#big-zone').offset().top){
          $("#big-zone").find(".line-scroll").css("top", "0");
          $("#big-zone").find(".line-scroll").css("bottom", "unset");
			    $("#big-zone").find(".line-scroll").css("position", "absolute");
        }
        if($(window).scrollTop() >= $('#zone1').offset().top && $(window).scrollTop() < $('#big-zone').offset().top){
          //$("#big-zone").find(".line-scroll").css("top",$(window).scrollTop()+"px");
          //$(".zone-droite-s").addClass("col-lg-offset-6");
          $(".nav-range").removeClass("actif");
          $(".nav-1").addClass("actif");
          console.log("nav-1")
        }
        if($(window).scrollTop() >= $('#big-zone').offset().top && $(window).scrollTop() < $('#outils').offset().top){
          //$("#big-zone").find(".line-scroll").css("top",$(window).scrollTop()+"px");
          //$(".zone-droite-s").addClass("col-lg-offset-6");
          /* let size = ($('#big-zone').offset().top +$('#big-zone').height() - $('#big-zone').offset().top)
          let pos = $(window).scrollTop() - $('#big-zone').offset().top; */
          $("#big-zone").find(".line-scroll").css("top", "0");
			    $("#big-zone").find(".line-scroll").css("position", "fixed");
          $("#big-zone").find(".line-scroll").css("bottom", "unset");
        }
        if($(window).scrollTop() >= $('#big-zone').offset().top && $(window).scrollTop() < $('#zone4').offset().top){

          $(".nav-range").removeClass("actif");
          $(".nav-2").addClass("actif");
          console.log("nav-2")
        }
        
        if($(window).scrollTop() >= $('#big-zone').offset().top && $(window).scrollTop() >= $('#outils').offset().top -10){
          $("#big-zone").find(".line-scroll").css("top", "unset");
          $("#big-zone").find(".line-scroll").css("bottom", "0");
			    $("#big-zone").find(".line-scroll").css("position", "absolute");
        }
        if($(window).scrollTop() >= $('#zone4').offset().top && $(window).scrollTop() < $('#zone6').offset().top){
          //$("#big-zone").find(".line-scroll").css("top","600%");
          //$(".zone-droite").removeClass("col-lg-offset-6");
          //$("#service-roll").addClass("out");
          $(".nav-range").removeClass("actif");
          $(".nav-3").addClass("actif");
          $("#nav-bar").css("z-index",1000);
          console.log("nav-3")
        }
        if($(window).scrollTop() >= $('#zone6').offset().top && $(window).scrollTop() < $('#zone8').offset().top){
          //$(".pop-logo img").css("opacity",1);
          //$(".pop-logo img").css("animation"," pop 2s");
          $(".nav-range").removeClass("actif");
          $(".nav-4").addClass("actif");
          $("#nav-bar").css("z-index",1000);
        }
        if($(window).scrollTop() >= $('#zone8').offset().top){
          $(".nav-range").removeClass("actif");
          $(".nav-5").addClass("actif");
        }
        if($(window).scrollTop() >= $('#big-zone').offset().top+$('#big-zone').height()/6*5){
          //$("#service-roll").addClass("out");
        }
        if($(window).scrollTop() >= $('#big-zone').offset().top-10 && $(window).scrollTop() <= ($('#big-zone').offset().top + $('#big-zone').height()) -10){
          let size = ($('#big-zone').offset().top +$('#big-zone').height() - $('#big-zone').offset().top)
          let pos = $(window).scrollTop() - $('#big-zone').offset().top;
					$("#active-line").css("height", ( (pos/ size) *100)+ '%');
        }
        if(window.matchMedia("(min-width: 992px)").matches){
          if($(window).scrollTop() < $('#big-zone').offset().top){
            $("#nav-bar").addClass("black");
          }
          if($(window).scrollTop() >= $('#big-zone').offset().top && $(window).scrollTop() < $('#zone4').offset().top-10){
            $("#nav-bar").removeClass("white");
            $("#nav-bar").removeClass("black");
            $(".contact-link").removeClass("black");
          }
          if($(window).scrollTop() >= $('#zone4').offset().top && $(window).scrollTop() < $('#zone5').offset().top-10){
            $("#nav-bar").addClass("white");
            $(".contact-link").addClass("black");
          }
          if($(window).scrollTop() >= $('#zone5').offset().top && $(window).scrollTop() < $('#zone6').offset().top-10){
            $("#nav-bar").addClass("white");
            $(".contact-link").addClass("black");
          }
          if($(window).scrollTop() >= $('#zone6').offset().top-10  /* && $(window).scrollTop() < $('#zone7').offset().top-10 */){
            $("#nav-bar").removeClass("white");
          }
          
          /* dessin svg */
          /* if($(window).scrollTop() >= $('#site').offset().top - 10){
            $("#ecransvg").find("path").css("animation","dash 2s ease-out forwards");
          }
          if($(window).scrollTop() >= $('#pwa').offset().top - 10){
            $("#phonesvg").find("path").css("animation","dash 4s ease-out forwards");
          }
          if($(window).scrollTop() >= $('#outils').offset().top - 10){
            $("#ordisvg").find("path").css("animation","dash 2s ease-out forwards");
          } */
          /* if($(window).scrollTop() >= $('#zone7').offset().top){
            $("#nav-bar").addClass("white");
            $(".contact-link").addClass("black");
          } */
        }
        if (window.matchMedia("(max-width: 992px)").matches) {
          /* icons  change color */
          if($(window).scrollTop()+$(window).height() < $('#zone4').offset().top){
            $("#nav-bar").removeClass("white");
          }
          if($(window).scrollTop()+$(window).height() >= $('#zone4').offset().top && $(window).scrollTop()+$(window).height() < $('#zone6').offset().top ){
            $("#nav-bar").addClass("white");
          }
          if($(window).scrollTop()+$(window).height() >= $('#zone6').offset().top && $(window).scrollTop()+$(window).height() < $('#zone8').offset().top ){
            $("#nav-bar").removeClass("white");
          }
          if($(window).scrollTop()+$(window).height() >= $('#zone8').offset().top ){
            $("#nav-bar").removeClass("white");
          }
          else{$("#nav-bar").css("position","fixed");$("#nav-bar").css("top",0);}
          
          
        }
        /* dessin svg */
        if($(window).scrollTop() >= $('#site').offset().top - midHeight){
          $("#ecransvg").find("path").css("animation","dash 2s ease-out forwards");
        }
        if($(window).scrollTop() >= $('#pwa').offset().top - midHeight){
          $("#phonesvg").find("path").css("animation","dash 4s ease-out forwards");
        }
        if($(window).scrollTop() >= $('#outils').offset().top - midHeight){
          $("#ordisvg").find("path").css("animation","dash 2s ease-out forwards");
        }
        
        if($(window).scrollTop() >= $('#metier').offset().top - midHeight && $(window).scrollTop() < $('#site').offset().top - midHeight){
          menuActive("metier", "text-color-orange")
        }
        if($(window).scrollTop() >= $('#site').offset().top - midHeight && $(window).scrollTop() < $('#pwa').offset().top - midHeight){
          menuActive("site", "text-color-orange")
        }
        if($(window).scrollTop() >= $('#pwa').offset().top - midHeight && $(window).scrollTop() < $('#outils').offset().top - midHeight){
          menuActive("pwa", "text-color-orange")
        }
        if($(window).scrollTop() >= $('#outils').offset().top - midHeight /* && $(window).scrollTop() < $('#zone7').offset().top */){
          menuActive("outils", "text-color-orange")
        }
    }})
      function scrollTo(id){
        if(onMove){
          
          return;
        }else{
          onMove = true;
          $("html,body").animate({
            scrollTop: $(id).offset().top,
        },0, "easein", function() {
          onMove = false;
        });
        }
      }
      function menuActive(active, activeClass){
        let item = $('#menu-digital')[0]?.children[0]?.children
        for(let element of item) {
          if(element.children[0].attributes['data-id'].value != active){
            $(element.children[0]).removeClass(activeClass)
          }else{
            $(element.children[0]).addClass(activeClass)
          }
        };
      }
      $('.scrollTo').on('click', function (e) {
        if(onMove){
          e.preventDefault();
          return;
        }else{
          onMove = true;
          var position = "";
          position = "#"+ $(this).attr("data-id");
          $("html,body").animate({
              scrollTop: $(position).offset().top
          },0, function() {
            onMove = false;
          });
        }
      })
      /***********************************************************
      * 	Animation dessins services pc
      */
        var i = 1;
        while( i <= 8)
        {
          var path = document.querySelector('#f'+i);
          var length = path.getTotalLength();
          path.style.transition = path.style.WebkitTransition =
            'none';
          // Set up the starting positions
          path.style.strokeDasharray = length + ' ' + length;
          path.style.strokeDashoffset = length;
          path.getBoundingClientRect();
          i++;
        }
        
      /***********************************************************
      * 	Animation dessins services smartphone
      */
        var i = 1;
        while( i <= 11)
        {
          var path = document.querySelector('#s'+i);
          var length = path.getTotalLength();
          path.style.transition = path.style.WebkitTransition =
            'none';
          // Set up the starting positions
          path.style.strokeDasharray = length + ' ' + length;
          path.style.strokeDashoffset = length;
          path.getBoundingClientRect();
          i++;
        }
        
      /***********************************************************
      * 	Animation dessins services metiers
      */
        var i = 1;
        while( i <= 18)
        {
          var path = document.querySelector('#o'+i);
          var length = path.getTotalLength();
          path.style.transition = path.style.WebkitTransition =
            'none';
          // Set up the starting positions
          path.style.strokeDasharray = length + ' ' + length;
          path.style.strokeDashoffset = length;
          path.getBoundingClientRect();
          i++;
        }
      
        
        
    }
  })

  return (
    <>
        <Layout preview={preview} siteEnv={siteEnv} isIndex={true}>
            <Head>
              <title>Alphalives : Agence de developpement web à Paris</title>
              <meta name="description" content="Alphalives, agence spécialisée dans le développement sur mesure. Logiciels métiers sur mesure, Sites internet, Application mobiles, Newsletters, Landing pages" />
              <link href="https://www.alphalives.com/" rel="canonical"/>
              <meta name="robots" content="index, follow"/>
            </Head>
            <section id="zone1" className="h-screen relative z-30 overflow-hidden">
              
              <div className="absolute fond-gris left-0 top-0 w-full h-screen z-10">
                <video autoPlay={true} muted={true} loop={true} playsInline={true} className="block w-full h-full z-40 object-cover">
                  <source src="/video/typing1.mp4.mp4" type="video/mp4"/>
                  <source src="/video/typing1.webmsd.webm" type="video/webm"/>
                  <source src="/video/typing1.oggtheora.ogv" type="video/ogg"/>
                </video>
              </div>       
              
              <svg className="z-10" xmlns="http://www.w3.org/2000/svg" id="svg" >
                <mask id="mask" x="0" y="0" width="100%" height="100%">
                  <rect x="0" y="0" width="100%" height="100%" fill="#FFF"  />
                  <g id="svgLogo"></g>
                </mask>
              </svg>
              {/* <Image className="absolute left-0 top-0 object-cover z-20" layout="fill" src="/imgs/bg-video.svg" /> */}
              <div className="absolute  container px-4 mx-auto z-30 home-title-banner" style={{
                "top": "50%",
                "left": "50%",
                "transform": "translate(-50%,-50%)",
                "margin-left": "7%"
              }}>
                <div className="grid gap-4 grid-cols-12">
                  <div className="col-span-12 md:col-span-12 md:col-start-2" id="digital-title">
                    <h1 className="text-4xl lg:mb-8 lg:text-7xl text-white leading-none">Agence de <br />développement<br />
                    <span className="text-color-primary">web & mobile</span></h1>
                    <p className=" text-white font-thin">
                      Alphalives, <a className="font-thin link-global" href="/agence-web-paris/" title="Agence web">agence web à Paris</a> spécialisée dans le développement sur mesure depuis 2001.</p>
                  </div>
                </div>
              </div> 
              <div  data-id="big-zone" className="scrollTo content-scroll-down" id="digital-scroll">
                <div className="block-round"></div>
                <span className="text-sm block content-text">
                  Découvrez l'agence
                </span>
              </div>
            </section>

            <section id="big-zone" className="relative z-20" ref={inputRef}>
              <div className="px-4 lg:px-0 mx-auto z-30">

                <div className="grid gap-4 grid-cols-12 relative line-menu text-grey zone-nos-services" id="metier">
                  <div className="col-span-12 md:col-span-4 md:col-start-7 py-12">
                    <h2 className="text-4xl mb-6 text-color-text">
                      <a href="/digitalisation" alt="Digitalisation">Notre métier,<br/><span className="text-color-primary">vous digitaliser</span></a>
                    </h2>
                    <p className="text-lg text-color-text mb-4">
                      Alphalives est une agence digitale qui vous conseille 
                      et développe des outils web sur mesure 
                      afin de vous amener à votre plus haut niveau de performance. Nous travaillons avec des grands comptes, des PME et des Startups.</p>
                      <a className="text-color-primary text-lg link-read-plus" href="/digitalisation" alt="Digitalisation"><span>En savoir plus</span><span className="dvi-hr"></span></a>

                  </div>
                </div>

                <div className="grid gap-4 grid-cols-12 relative line-menu text-grey zone-nos-services" id="site">
                  <div className="col-span-4 sm:col-span-5 lg:col-span-6 relative py-12">{/* col-span-12 relative md:col-span-5 py-12 */}
                    <EcranSvg className=""/>
                    {/* <div className="img-section">
                    <Image 
                    layout="responsive" 
                      width={631}
                      height={485}
                    src="https://images.ctfassets.net/a39bg4ghqkpe/7eM9tOx0M6iizr8Q3yjjy5/572b20447470306f3aaa28ce6fd60d27/logo-pc.png?fm=webp&q=80" alt="pc" />
                    </div> */}
                  </div>

                  <div className="col-span-7 sm:col-span-6 py-12 md:col-start-7"> {/* col-span-12 md:col-span-4 py-12 md:col-start-7 */}
                    <h3 className="text-lg text-color-text mb-6 text-color-primary">01<br/>
                      <a href="/creation-site-internet" alt="creation sites internet">
                        <span className="text-grey text-4xl">Développement<br />de Sites Internet</span>
                      </a>
                    </h3>

                    <p className="text-lg text-color-text mb-6">Alphalives développe des sites internet depuis plus de 15
                      ans. Nous réalisons votre site sur mesure ou à l'aide d'un
                      CMS (wordpress, joomla, drupal) en fonction de vos
                      besoins. Nous vous accompagnons tout au long du projet:
                      audit, conseils, UX/UI design, développement,
                      référencement, hébergement.</p>
                    <div className="service-list mb-4"><i className="fa fa-caret-right" aria-hidden="true"></i> Un accompagnement de A à Z<br/><i className="fa fa-caret-right" aria-hidden="true"></i> 15 ans d'expérience<br/><i className="fa fa-caret-right" aria-hidden="true"></i> Sur mesure en fonction de vos besoins</div>
                    <a className="text-color-primary text-lg link-read-plus" href="/creation-site-internet" alt="creation sites internet"><span>En savoir plus</span><span className="dvi-hr"></span></a>
                  </div>
                </div>

                <div className="grid gap-4 grid-cols-12 relative line-menu text-grey zone-nos-services" id="pwa">
                  <div className="col-span-4 sm:col-span-5 lg:col-span-6 relative py-12">{/* col-span-12 md:col-span-6 */}
                    <PhoneSvg className=""/>
                  {/*   <div className="img-section">
                      <Image layout="responsive" 
                        width={583}
                        height={576}
                        src="https://images.ctfassets.net/a39bg4ghqkpe/aCTkRMdicmLiqy6kQ61MJ/6a33f8f4b6a4ea7a10651faee3715cfb/logo-telephone.PNG?fm=webp&q=80"  alt="telephone" />
                    </div> */}
                  </div>

                  <div className="col-span-7 sm:col-span-6 py-12 md:col-start-7">{/* col-span-12 md:col-span-4  */}
                    <h3 className="text-lg text-color-text mb-6 text-color-primary">02<br/>
                      <a href="/agence-application-mobile" alt="agence application mobile">
                        <span className="text-grey text-4xl">Applications mobiles & Progressive web Apps</span>
                      </a>
                    </h3>

                    <p className="text-lg text-color-text mb-6">Alphalives développe des applications mobiles grand
                      public ou professionnelles. Nous optimisons le temps de
                      développement en utilisant une technologie hybride qui
                      permet de coder une seule application compatible avec
                      Androïd et iOs</p>
                    <div className="service-list mb-4"><i className="fa fa-caret-right" aria-hidden="true"></i> Grand public ou professionnel<br/><i className="fa fa-caret-right" aria-hidden="true"></i> Pour androïd ou iOs<br/><i className="fa fa-caret-right" aria-hidden="true"></i> Temps de développement optimisé</div>
                    <a className="text-color-primary text-lg link-read-plus" href="/agence-application-mobile" alt="agence application mobile"><span>En savoir plus</span><span className="dvi-hr"></span></a>
                  </div>
                </div>

                <div className="grid gap-4 grid-cols-12 relative line-menu text-grey zone-nos-services" id="outils">
                  <div className="col-span-4 sm:col-span-5 lg:col-span-6 relative py-12">{/* col-span-12 relative md:col-span-6 py-12 */}
                    <OrdiSvg className=""/>
                  {/*   <div className="img-section">
                      <Image layout="responsive" 
                        width={716}
                        height={429} src="https://images.ctfassets.net/a39bg4ghqkpe/7d3jJOO3Ne5tuD5847CuYL/4483dcb4e686356e214a1ae60f747883/logo-chart.PNG?fm=webp&q=30"  alt="chart" />
                  </div> */}
                 </div>

                  <div className="col-span-7 sm:col-span-6 py-12 md:col-start-7"> {/* col-span-12 md:col-span-4 py-12 md:col-start-7 */}
                    <h3 className="text-lg text-color-text mb-6 text-color-primary">03<br/>
                    <a href="/le-developpement-sur-mesure" alt="developpement sur mesure">
                    <span className="text-grey text-4xl">
                      Développement sur mesure<br />d'outils métiers et de logiciels SAAS</span></a></h3>
                    
                    <p className="text-lg text-color-text mb-6">Nous concevons des progiciels sur mesure qui
                      correspondent à votre métier et à vos process les plus
                      complexes. Accessible sur internet depuis votre ordinateur
                      ou votre mobile, ils vous permettront de : gagner du
                      temps, centraliser vos données, générer des reportings
                      détaillés et d'optimiser vos coûts de fonctionnements.</p>
                    <div className="service-list mb-4"><i className="fa fa-caret-right" aria-hidden="true"></i> Sur mesure<br/><i className="fa fa-caret-right" aria-hidden="true"></i> Gain de temps de pilotage<br/><i className="fa fa-caret-right" aria-hidden="true"></i> Centralisation des données<br/><i className="fa fa-caret-right" aria-hidden="true"></i> Reporting avancé<br/><i className="fa fa-caret-right" aria-hidden="true"></i> Fonctionnent sur mobile</div>
                    <a className="text-color-primary text-lg link-read-plus" href="/le-developpement-sur-mesure" alt="developpement sur mesure"><span>En savoir plus</span><span className="dvi-hr"></span></a>
                  </div>
                </div>

                {/* <div className="grid gap-4 grid-cols-12 relative line-menu text-grey zone-nos-services" id="comm">
                  <div className="col-span-12 relative md:col-span-6 py-12">
                    <img className="img-section" src="imgs/logo-tableau.PNG" />
                  </div>

                  <div className="col-span-12 md:col-span-4 py-12 md:col-start-7">
                    <p className="text-lg text-color-text mb-6 text-color-primary">04<br/><span className="text-grey text-4xl">Outils de communication</span></p>

                    <p className="text-lg text-color-text mb-6">Landing page, emailing, newsletter ou site évenementiel,
                      nous vous réalisons tous vos outils de communication.

                      Notre expérience avec de nombreuses agences de
                      communication nous permet de nous adapter à vos
                      besoins de réactivité, de souplesse et de qualité.</p>
                    <a className="text-color-primary text-lg link-read-plus" href="#"><span>En savoir plus</span><span className="dvi-hr"></span></a>
                  </div>
                </div> 
                <div className="grid gap-4 grid-cols-12 relative line-menu text-grey zone-nos-services" id="logiciel">
                  <div className="col-span-12 relative md:col-span-6 py-12">
                    <img className="img-section" src="imgs/logo-web.PNG" />
                  </div>

                  <div className="col-span-12 md:col-span-4 py-12 md:col-start-7">
                    <p className="text-lg text-color-text mb-6 text-color-primary">05<br/><span className="text-grey text-4xl">Logiciels SAAS</span></p>
                    <p className="text-lg text-color-text mb-6">Alphalives vous a développé des outils clés en mains. CRM,
                      intranets, outils de gestions. Ils sont rapides à mettre en
                      place, performants et vous permettent de ne pas avoir
                      d'investissements à réaliser.</p>
                      <div className="service-list mb-4"><i className="fa fa-caret-right" aria-hidden="true"></i> Performance<br/><i className="fa fa-caret-right" aria-hidden="true"></i> Rapide à mettre en place / clé en main<br/><i className="fa fa-caret-right" aria-hidden="true"></i> Tarif mensualisé</div>
                    <a className="text-color-primary text-lg link-read-plus" href="#"><span>En savoir plus</span><span className="dvi-hr"></span></a>
                  </div>
                </div>
*/}
                <div className="line-scroll hidden md:block">
                  <span className="line active" id="active-line"></span>
                </div>
                <div id="menu-digital" className={`${ menu ? 'active' : 'in' } menu-fixed left-custom-center`}>
                  <ul className="list-none p-0 text-right">
                    <li className="mb-8">
                      <p className="text-base text-gray font-thin hover:text-color-orange scrollTo" data-id="metier">Nos Services</p>
                    </li>
                    <li className="mb-8">
                      <div className="text-base font-thin text-gray hover:text-color-orange scrollTo" data-id="site">
                      <span>01</span><br/>
                        Sites Internet
                      </div>
                    </li>
                    <li className="mb-8">
                      <div className="text-base font-thin text-gray hover:text-color-orange scrollTo" data-id="pwa">
                        <span>02</span><br/>
                        Applications mobiles <br />& Progressive Web Apps
                      </div>
                    </li>
                    <li className="mb-8">
                      <div className="text-base font-thin text-gray hover:text-color-orange scrollTo" data-id="outils">
                        <span>03</span><br/>
                        Développement<br />sur mesure
                      </div>
                    </li>
                   
                   {/*
                    <li className="mb-8">
                      <a className="text-base font-thin text-gray hover:text-color-orange scrollTo" data-id="comm">
                        <span>04</span><br/>
                        Outils de communication
                      </a>
                    </li>
                    <li className="mb-8">
                      <a className="text-base font-thin text-gray hover:text-color-orange scrollTo" data-id="logiciel">
                        <span>05</span><br/>
                        Logiciels SAAS
                      </a>
                    </li>
*/}

                  </ul>
                </div>

              </div>
            </section>

            <section id="zone4" className="bg-color-orange relative z-30">
            
            <div className="img-user-alphalives absolute opacity-50">
            <Image 
            src="https://images.ctfassets.net/a39bg4ghqkpe/4IYl9QVI4teJDrE3RmVwYC/b4a537d5c101a1b2aae5c51817dfbc51/frederic-baudouin.png?fm=webp&q=30"  
            alt="Frédéric Baudouin" 
            layout="responsive" 
                      width={399}
                      height={600}
            />

          </div>

              <div className="container mx-auto px-4">
                <div className="grid gap-4 grid-cols-12 items-center relative">
                 
                  <div className="col-span-12 md:col-span-6 md:col-start-4 lg:pt-60 md:pt-12">
                    <div className="mb-6 relative">
                    <Image width="93" height="75" className="img-section-quote-1" alt="" src="/img/guillemets2.png" />
                      <h2 className="text-white text-4xl text-center mb-4">
                        Chez Alphalives nous poursuivons un objectif unique : vous faciliter le quotidien.
                      </h2>
                      <p className="text-white text-sm text-center">
                        Grâce à notre expertise et à notre méthodologie, nous vous développons des outils
                        sur mesure, afin de vous mener à votre plus haut niveau de performance.
                        Ainsi, quel que soit votre besoin, nous vous accompagnons depuis le conseil,
                        jusqu'a la livraison d'un outil permettant l'optimisation de votre activité.
                      </p>
                      <Image width="93" height="75" className="img-section-quote-2" alt="" src="/img/guillemets.png" />
                    </div>
                  
                    <div className="text-right ml-auto">
                      <p className="text-base text-white italic">
                        Frédéric Baudouin 
                        <br/>Directeur Fondateur
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </section>

            <section id="zone5" className="bg-color-orange relative z-30">
              <div className="container mx-auto px-4">

                <h2 className="text-5xl text-white mb-6 text-center">Notre méthodologie</h2>
                
                <div className="grid gap-4 grid-cols-12 items-center">

                  <div className="col-span-6 md:col-span-4 py-12">
                    <div className="relative text-white lg:text-right">
                      <div className="absolute lg:right-0 transform -translate-x-1/2 lg:transform-none left-1/2 z-10">
                        <p className="text-9xl opacity-30">1</p>
                      </div>
                      <div className="relative z-20">
                        <Image width="74" height="55" src="/imgs/icon-1.PNG" alt="audit site web" />
                        <h3 className="text-sm mb-9">Audit<br/>&nbsp;</h3>
                      </div>
                      <div className="relative lg:w-1/2 ml-auto z-20">
                        <p className="text-sm">
                          Notre équipe d'experts vous rencontre et vous suit afin de comprendre votre ou vos problématiques.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6 md:col-span-4 py-12">
                    <div className="relative text-white text-right lg:text-center">
                      <div className="absolute transform -translate-x-1/2 left-1/2 z-10">
                        <p className="text-9xl opacity-30">2</p>
                      </div>
                      <div className="relative z-20">
                        <Image width="70" height="79" src="/imgs/icon-2.PNG"  alt="performance site web" />
                        <h3 className="text-sm mb-9">Rapport et<br/> recommandations</h3>
                      </div>
                      <div className="relative lg:w-1/2 mx-auto z-20">
                        <p className="text-sm">
                        Nous réalisons et vous fournissons un rapport complet suivi de recommandations à mettre en place.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6 md:col-span-4 py-12">
                    <div className="relative text-white lg:text-left">
                      <div className="absolute transform -translate-x-1/2 lg:transform-none left-1/2 lg:left-0 z-10">
                        <p className="text-9xl opacity-30">3</p>
                      </div>
                      <div className="relative z-20">
                        <Image width="80" height="73" src="/imgs/icon-3.PNG"  alt="roadmap creation site internet" />
                        <h3 className="text-sm mb-9">Roadmap<br/> budgets</h3>
                      </div>
                      <div className="relative lg:w-1/2 z-20">
                        <p className="text-sm">
                        Nous analysons ensemble la partie du process à traiter ainsi que les fonctionnalités à développer.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6 md:col-span-4 py-12">
                    <div className="relative text-white text-right">
                      <div className="absolute right-0 z-10">
                        <p className="text-9xl opacity-30">4</p>
                      </div>
                      <div className="relative z-20">
                        <Image width="81" height="60" src="/imgs/icon-4.PNG"  alt="developpement sur mesure" />
                        <h3 className="text-sm mb-9">Développement du <br/> ou des outils</h3>
                      </div>
                      <div className="relative lg:w-1/2 ml-auto z-20">
                        <p className="text-sm">
                        Nous développons la solution en méthode agile en utilisant la technologie la plus adaptée à votre problématique.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6 md:col-span-4 py-12">
                    <div className="relative text-white lg:text-center">
                      <div className="absolute transform -translate-x-1/2 left-1/2 z-10">
                        <p className="text-9xl opacity-30">5</p>
                      </div>
                      <div className="relative z-20">
                        <Image width="82" height="72" src="/imgs/icon-5.PNG"  alt="formation internet" />
                        <h3 className="text-sm mb-9">Formation /sensibilisation <br/> des utilisateurs</h3>
                      </div>
                      <div className="relative z-20">
                        <p className="text-sm lg:w-1/2 mx-auto">
                        Nous sommes également organisme de formation et pouvons accompagner vos équipes dans la prise en main des outils
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6 md:col-span-4 py-12">
                    <div className="relative text-white text-right lg:text-left">
                      <div className="absolute transform -translate-x-1/2 lg:transform-none left-1/2 lg:left-0 z-10">
                        <p className="text-9xl opacity-30">6</p>
                      </div>
                      <div className="relative z-20">
                        <Image width="88" height="72" src="/imgs/icon-6.PNG"  alt="evolution" />
                        <h3 className="text-sm mb-9">Evolution du <br/> ou des outils</h3>
                      </div>
                      <div className="relative lg:w-1/2 z-20">
                        <p className="text-sm">
                        Nous analysons ensemble les optimisations et évolutions à apporter à chaque outil développé et les mettons en place.
                        </p>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </section>

            {/* <section id="zone6" className="bg-white py-20 relative z-30">
              <div className="container mx-auto px-4">
                
                <h2 className="text-5xl text-grey mb-6 text-center">Ils nous font <span className="text-color-primary">confiance</span></h2>
                
                <div className="grid grid-cols-12 items-center">
                  <div className="col-span-12 md:col-span-10 md:col-start-2">
                    <div className="grid gap-4 grid-cols-12 items-center">
                      {marques.map((marque, index) => (
                        <div className="col-span-6 md:col-span-4 lg:col-span-3" key={marque.nom+"-"+index}>
                          <Image src={marque.image.url+'?w=227&h=142&q=60&fm=webp'} width="227" height="142" alt={marque.alt}/>
                        </div>
                      ))}
                      
                    </div>
                  </div>
                </div>

              </div>
            </section> */}
            <Marques marques={marques}></Marques>
            
            <ContactMap siteEnv={siteEnv}></ContactMap>

        

            <div className="nav-bar h-screen fixed top-1/2 transform -translate-y-1/2 right-0 z-50 hidden lg:flex" id="nav-bar">
              <div className="bloc-menu lg:block hidden">
                <div className="nav-range nav-1 no-padding">
                <div className="nav-menu nav-1 no-padding actif"><span className='scrollTo' data-id="zone1" >Agence</span></div><div className="nav-fil"><hr/></div>
                </div>
                <div className="nav-range nav-2 no-padding actif">
                <div className="nav-menu nav-2 no-padding actif"><span className='scrollTo' data-id="big-zone" >Services</span></div><div className="nav-fil"><hr/></div>
                </div>
                <div className="nav-range nav-3 no-padding">
                <div className="nav-menu nav-3 no-padding actif"><span className='scrollTo' data-id="zone4" >Notre méthode</span></div><div className="nav-fil"><hr/></div>
                </div>
                <div className="nav-range nav-4 no-padding">
                <div className="nav-menu nav-4 no-padding actif"><span className='scrollTo' data-id="zone6">Nos Clients</span></div><div className="nav-fil"><hr/></div>
                </div>
                <div className="nav-range nav-5 no-padding">
                  <div className="nav-menu nav-5 no-padding actif"><span className='scrollTo' data-id="zone8" >Contact</span></div><div className="nav-fil"><hr/></div>
                </div>
              </div>
            </div>
      </Layout>
    </>
  )
}
