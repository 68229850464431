import { getAllPostsForHome, getPostAndMorePosts, getAllMarques } from '../lib/api'
import HomepagePWA from '../components/homepage-pwa'
import HomepageALPHA from '../components/homepage-alphalives'
import PageTemplate from '../components/page-template'
import {checkEnv, getOtherEnv} from '../lib/utils'
import ErrorPage  from 'next/error'


export default function Index({ preview, allPosts, allExemples, siteEnv, articles, marques}) {

  if (checkEnv(siteEnv) === false) {
    return (<ErrorPage statusCode={404} />)
  }
  
  return (
    <>
      
      {siteEnv == "alphalives" && <HomepageALPHA preview={preview} siteEnv={siteEnv} marques={marques}></HomepageALPHA>}
      {siteEnv == "pwafr" && <HomepagePWA preview={preview} allPosts={allPosts} allExemples={allExemples} siteEnv={siteEnv}></HomepagePWA>}
      {getOtherEnv(siteEnv) && <PageTemplate preview={preview} article={articles} siteEnv={siteEnv} marques={marques}></PageTemplate>}
      
    </>
  )
}

export async function getStaticProps({ preview = false }) {
  const allPosts = (await getAllPostsForHome(preview)) ?? []
  const siteEnv = process.env.CONTENTFUL_SITE_TAG ?? "pwafr"
  const articles = (await getPostAndMorePosts('', preview)) ?? []
  const marques = (await getAllMarques(preview)) ?? []
  //console.log(articles);


  return {
    props: { 
      preview, 
      allPosts: allPosts?.articles ?? null,
      allExemples: allPosts?.exemples ?? null,
      articles : articles.article ?? null,
      siteEnv,
      marques
    },
    revalidate: 10,
  }
}
